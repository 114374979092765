import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';


export const MobileNavContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #2b2d2f;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: ease-in-out 0.2s;
    opacity: ${({ isOpen }) => ( isOpen ? '100%' : '0%' )};
    top: ${({ isOpen }) => ( isOpen ? '0': '-200%')};
`

export const CloseIcon = styled(FaTimes)`
    color: whitesmoke;
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    size: 2rem;
`

export const MobileNavWrapper = styled.div`
    color: whitesmoke;
`

export const MobileNavMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 3rem);
    text-align: center;
    padding: 0;

    @media screen and (max-wdith: 768px){
        grid-template-rows: repeat(6, 2rem);
    }
`

export const MobileNavLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: ease-in-out 0.2s;
    color: whitesmoke;
    cursor: pointer;

    &.active{
        color: #D4AF37;
        transition: ease-in-out 0.2s;
    }
`
