import React, {useState } from 'react'
import { firestore } from '../../firebase';
import { useLocation } from 'react-router';

import Testimonial from './Testimonial';
import './mentorship.css';
// import mentor_profile_pic from '../../../public/mentro.jpg'

const signMeUp = (callback, signups) => {
    firestore.collection('codeificare-pages').doc('mentorship').update({ signups: signups + 1});
    callback(signups + 1);
}

const Mentorship = (props) => {
    const [signups, setSignups] = useState();

    firestore.collection('codeificare-pages').doc('mentorship').get().then(record => {
        setSignups(record.data().signups);
    });

    const query = new URLSearchParams(useLocation().search)
    const promoCode = query.get('promo') ? query.get('promo') : "";

    let gumroadUrl = "";
    if (promoCode.toLowerCase() === "vault2021" || signups < 10)
        // Free signup
        gumroadUrl = "https://forms.gle/rMNUsQhFVmU59qcJ7";
    else if (signups < 20)
        // $10 signup
        gumroadUrl = "https://gumroad.com/l/pBBHU";
    else if (signups < 30)
        // $20 signup
        gumroadUrl = "https://gumroad.com/l/zgHpX";
    else
        // $30 signup
        gumroadUrl = "https://gumroad.com/l/XIKXwe";

    return (
        <div class="page">
            <div class="content">
                <div id="mentorship-backsplash">
                    <h2>Are You Ready</h2>
                    <h1>To start your career in tech and achieve the reputation of a 10x developer?</h1>

                    <a href={gumroadUrl} onClick={() => {signMeUp(setSignups, signups)}}>sign me up</a>
                </div>
                <div id="what-can-mentroship-do-for-you"  class="section">
                    <h3>Why Do I Need a Mentor?</h3>
                    <p>
                        A mentor will help refine your technical skills, offer career guidance and can empathize with your struggles and
                        frustrations because they've been in your position before. 75% of employees consider having a mentor essential to
                        professional success. It's easy to undestand why. Employees with a mentor are 5 times more likely to receive a
                        promotions and experience a pay increase compared to employees without a mentor.
                    </p>
                    <p>
                        These 30 minute mentorship sessions are the first I've offered that are dedicated to achieving a 10x devloper reputation.
                        During your session you will be able to ask me about anything. Topics I've discussed before have ranged from the history
                        programming syntax, to how you can step into a team lead position.
                    </p>
                    <p>
                        If you're ready to level up and get noticed by the hot new tech startups, and FAANG companies reserve your session now.
                    </p>
                    <a href={gumroadUrl} onClick={() => {signMeUp(setSignups, signups)}}>ok ok... sign me up</a>
                </div>
                <div class="section">
                    <h3>Meet Your Mentor</h3>
                    <p>
                    <img style={{float: "left", height: "150px", "padding-right": "1em"}} src="mentor.jpg" alt="A dashingly handsome man taking a selfie of himself after hitting a perfect bullseye with a throwing axe."/>
                        Yo! I'm Derek. Over the last 10-years I've taught myself the most in-demand programming languages and technologies like Python, React.js, Git and Docker.
                        For 3-4 years now I've been mentoring individuals with a range of programming experience. Some had never written a single line of code,
                        others were fresh gradutes from a bootcamp or CS degree program and a few were professional developers wanting to step up their
                        game to the next level. During this time I discovered my passion for people and teaching. I want to use my experience as a
                        CS gradutate, independent contractor, and tech start-up employee to empower the next generation of developers and shape the future
                        of the tech industry.
                    </p>
                    <div style={{clear:"both"}} />
                    <a href={gumroadUrl} onClick={() => {signMeUp(setSignups, signups)}}>no seriously sign me up</a>
                </div>
                <div class="section">
                    <h3>testimonials</h3>
                    <div class="testimonials">
                        <Testimonial
                            content="Derek taught me why code matters and how people use these skills to bring passions to life. It was the framework I needed. Highly Recommended"
                            author="Graham W."
                        />
                        <Testimonial
                            content="Having Derek as a mentor gave me the confidence to jump feet first into programming. After a few sessions with Derek I felt confident I could find success as a software developer."
                            author="Angel J."
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mentorship
