import React from "react";

import logo from '../../codeificare_logo_small.png';
import {
  Nav,
  NavLogo,
  NavLink,
  MobileNavIcon,
  NavLinks,
} from './elements'
import SocialMedia from '../Social';

const NavBar = ({ toggleMobileNav }) => (
  <>
    <Nav>
      <NavLogo to="/">
      <img src={logo} alt="codeificare" />
      </NavLogo>
      <MobileNavIcon onClick={ toggleMobileNav } />
      <NavLinks>
        <NavLink to="/mentorship" activeStyle>Mentorship Program</NavLink>
        <NavLink to="/tech-in-ten" activeStyle>Tech-in-Ten</NavLink>
        <NavLink to="/articles" activeStyle>Projects</NavLink>
        <NavLink to="/podcast" activeStyle>Namespace Podcast</NavLink>
        <SocialMedia />
      </NavLinks>
    </Nav>
  </>
)

export default NavBar;
