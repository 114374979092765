import React from "react";

import {
  MobileNavContainer,
  CloseIcon,
  MobileNavWrapper,
  MobileNavMenu,
  MobileNavLink,
} from "./elements";

import SocialMedia from '../Social';


const MobileNav = ({ isOpen, toggleMobileNav }) => (
  <MobileNavContainer isOpen={isOpen} onClick={toggleMobileNav}>
      <CloseIcon onClick={toggleMobileNav}/>
      <MobileNavWrapper>
        <MobileNavMenu>
          <MobileNavLink to="mentorship" activeStyle>Mentorship Program</MobileNavLink>
          <MobileNavLink to="tech-in-ten" activeStyle>Tech-in-Ten</MobileNavLink>
          <MobileNavLink to="articles" activeStyle>Projects</MobileNavLink>
          <MobileNavLink to="podcast" activeStyle>Namespace Podcast</MobileNavLink>
          <SocialMedia />
        </MobileNavMenu>
      </MobileNavWrapper>
  </MobileNavContainer>
)

export default MobileNav;
