import React from 'react'
import Episode from './Episode.js';
import './podcast.css'

function PodcastPage() {
    return (
        <div class="podcasts">
            <Episode
                title="Ep. 11 - SOLID Design Principles Part II"
                description="Of course we were unable to discuss the biggest secret to programming excellence in a single episode. Check out SOLID Design Principles Part 2 for a complete picture of the 5 steps to OOP perfection!"
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/psgK2AJ3a5A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 10 - SOLID Design Principles"
                description="The famous SOLID design principles, elemental to writing clean code, are presented by Jordan and Derek to help guide your pursuit in ideal code design."
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/NWO_dSE9QOs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 9 - Contracting & Consulting"
                description="Jordan and Derek discuss their various consulting and contracting experiences in an episode which doubles as a therapy session and advice into the deciding factors between contracting, consulting, and full time roles."
            >
            <iframe width="267" height="150" src="https://www.youtube.com/embed/iK0lyYejD1w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 8 - Reaction to Stack Overflow Developer Survey 2020"
                description="Our longest episode yet! Or many mini episodes in one ... Derek and Jordan provide commentary on the 2020 Stack Overflow Developer Survey and interpret what these results may imply about the state of the tech industry."
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/ce8lieETwHY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 7 - Rethinking Technical Debt"
                description="We've all worked with a code base where we were afraid to change anything. That's the result of technical debt. Today we redefine technical debt, talk about where it comes from, how much it actually costs, but most importantly how to pay it down, and accrue less to begin with."
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/w_8sTx0Eht0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 6 - Fixing the Gender Wage Gap"
                description="Listen as we fix the gender wage gap right here in this podcast! A discussion of statistics, experiences, and ideas."
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/VPvtord5XVM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 5 - Test Driven Development"
                description="Number one best practice: don't be dogmatic!

                Listen on your favorite podcast app! Do not forget to subscribe!"
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/arGTDa4_2iw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 4 - Programming Languages to Learn in 2020"
                description="Figure out which language will help you achieve your goals + our top tips for learning a new language"
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/r2er7qMJATI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 3 - Is Python a fad?"
                description="Ever heard something so ridiculous you had to start a podcast? We have!

                Listen on your favorite podcast app! Do not forget to subscribe!"
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/RwX-9KWlBsI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
            <Episode
                title="Ep. 0 - Intro"
                description="Who are we and why should you listen to us? No one, and ‘cause you want to.

                Listen on your favorite podcast app! Do not forget to subscribe"
            >
                <iframe width="267" height="150" src="https://www.youtube.com/embed/-OWWNByfZCI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Episode>
        </div>
    )
}

export default PodcastPage;
