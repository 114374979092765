import React from 'react';
import logo from '../../codeificare_logo.png'
import SocialMedia from '../../components/Social';
import './epigram.css';

function Epigram(props){
  return (
    <div className="epigram">
      <img id="codeificare-logo" src={logo} alt="codeificare" />
      {props.children} 
      <SocialMedia visible={props.socialVisible} />
    </div>
  );
}

export default Epigram;
