import React from 'react';
import Epigram from '../../components/Epigram';
import './home.css';

function HomePage() {
  return (
    <Epigram socialVisible={false} >
      <span className="epigram-home">Codeificare is a blend of the latin words for code and craftsman.</span>
      <span className="epigram-home">It embodies the mindset and discipline of developers that desire to master the craft of coding and</span>
      <span className="epigram-home">contribute back to the development community.</span>
    </Epigram>
  )
}

export default HomePage;
