import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyALbPPOM5ZXNWQZ95-CoxvsAuCfp_AplSk",
  authDomain: "codeificare-site.firebaseapp.com",
  projectId: "codeificare-site",
  storageBucket: "codeificare-site.appspot.com",
  messagingSenderId: "418368141039",
  appId: "1:418368141039:web:7258803777ea2598580d1a",
  measurementId: "G-P6NCZ31902"
};
const fire = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
firestore.settings({ timestampsInSnapshots: true });
firebase.analytics();

export { fire, firestore };
