import React from 'react';
import Epigram from '../../components/Epigram';

function ComingSoon() {
  return (
    <div class="coming-soon">
      <Epigram>
        <span>This Page is Coming Soon</span>
        <span>Follow us on social media for updates on our progress</span>  
      </Epigram>
    </div>
  )
}

export default ComingSoon;
