import React from 'react'
import './episode.css'

const Episode = ({title, description, children}) => (
    <div class="episode">
        { children }
        <div class="episode-details">
            <h2 class="episode-title">{title}</h2>
            <p>{description}</p>
        </div>
    </div>
)

export default Episode;
