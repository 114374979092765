import React from "react";
import PropTypes from 'prop-types';
import { faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './social.css';

SocialMedia.propTypes = {
  visible: PropTypes.bool,
}

SocialMedia.defaultProps = {
  visible: true
}

export default function SocialMedia(props) {
  const content = props.visible ? (
    <div className="social-container">
      <a href="https://twitter.com/D3r3kdrumm0nd" className="social-media-link">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
      <a href="https://github.com/ezzy1337" className="social-media-link">
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </a>
    </div>
  ) :
  (<span></span>);

  return content;
}
