import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import NavBar from '../Navbar';
import MobileNav from '../MobileNav';
import HomePage from '../../pages/Home';
import Podcast from '../../pages/Podcast';
import ComingSoon from '../../pages/ComingSoon';
import Mentorship from '../../pages/Mentorship';



const Header = (props) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const toggleMobileNav = () => {
    console.log('BANANA');
    setIsOpen(!isOpen);
  }

  return (
    <Router>
      <MobileNav isOpen={isOpen} toggleMobileNav={toggleMobileNav}/>
      <NavBar toggleMobileNav={toggleMobileNav} />
      <Switch>
        <Route path="/mentorship">
          <Mentorship />
        </Route>
        <Route path="/tech-in-ten">
          <ComingSoon />
        </Route>
        <Route path="/articles">
          <ComingSoon />
        </Route>
        <Route path="/open-source">
          <ComingSoon />
        </Route>
        <Route path="/podcast">
          <Podcast />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>
  )
}

export default Header;
