import React from "react";
import './testimonial.css'


const Testimonial = ({content, author}) => {
  return (
    <div class="testimonial">
      <p>"{content}" <span>- {author}</span></p>
    </div>
  )
}

export default Testimonial
