import React from 'react';

import './App.css';
import Header from './components/Header';

function App() {
  document.title = 'codeificare';
  return (    
    <div className="App">   
      <Header />
    </div>
  );
}

export default App;
